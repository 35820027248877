@font-face {
  font-family: "Open Sans";
  font-weight: 400;
  font-display: swap;
  font-style: normal;
  src: url("./fonts/open-sans-regular.woff2") format("woff2");
}

@font-face {
  font-family: "Open Sans";
  font-weight: 400;
  font-display: swap;
  font-style: italic;
  src: url("./fonts/open-sans-italic.woff2") format("woff2");
}

@font-face {
  font-family: "Open Sans";
  font-weight: 600;
  font-display: swap;
  font-style: normal;
  src: url("./fonts/open-sans-600.woff2") format("woff2");
}

@font-face {
  font-family: "Open Sans";
  font-weight: 600;
  font-display: swap;
  font-style: italic;
  src: url("./fonts/open-sans-600italic.woff2") format("woff2");
}

@font-face {
  font-family: "Trade Gothic";
  font-weight: bold;
  font-display: swap;
  font-style: normal;
  src: url("./fonts/TradeGothicLTStd-Bold.woff2") format("woff2");
}

body {
  background-color: #f5f5f5;
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  font-family: "Trade Gothic", "Helvetica Neue", "Helvetica", sans-serif;
}

#root {
  min-height: 100vh;
  display: grid;
  align-content: stretch;
  justify-content: center;
  margin: 0;
  grid-template-columns: minmax(0, 1fr);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
